@import "variables";

@font-face {
  font-family: '#{$alevri-icon-font-family}';
  src:
    url('#{$alevri-icon-font-path}/#{$alevri-icon-font-family}.woff2?wxuahc') format('woff2'),
    url('#{$alevri-icon-font-path}/#{$alevri-icon-font-family}.ttf?wxuahc') format('truetype'),
    url('#{$alevri-icon-font-path}/#{$alevri-icon-font-family}.woff?wxuahc') format('woff'),
    url('#{$alevri-icon-font-path}/#{$alevri-icon-font-family}.svg?wxuahc##{$alevri-icon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.alevri-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$alevri-icon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alevri-icon-heart {
  &:before {
    content: $alevri-icon-heart;
  }
}
.alevri-icon-envelope {
  &:before {
    content: $alevri-icon-envelope;
  }
}
.alevri-icon-printer {
  &:before {
    content: $alevri-icon-printer;
  }
}
.alevri-icon-user {
  &:before {
    content: $alevri-icon-user;
  }
}
.alevri-icon-location {
  &:before {
    content: $alevri-icon-location;
  }
}
.alevri-icon-magnifier {
  &:before {
    content: $alevri-icon-magnifier;
  }
}
.alevri-icon-cross {
  &:before {
    content: $alevri-icon-cross;
  }
}
.alevri-icon-menu {
  &:before {
    content: $alevri-icon-menu;
  }
}


