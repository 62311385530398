@mixin font-smoothing($value: antialiased) {
 @if $value == antialiased {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
 @else {
   -webkit-font-smoothing: subpixel-antialiased;
   -moz-osx-font-smoothing: auto;
 }
}

@mixin sprite-retina($name, $sprite, $sprite_retina, $offset-x: 0, $offset-y: 0, $dimensions: false) {
 background-image: sprite-url($sprite);
 background-position: sprite-position($sprite, $name, $offset-x, $offset-y);
 background-repeat: no-repeat;

 @media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 3/2), (min--moz-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 144dppx) {
   background-image: sprite-url($sprite_retina);
   background-position: ((nth(sprite-position($sprite_retina, $name), 1)/2) + $offset-x) ((nth(sprite-position($sprite_retina, $name), 2)/2) + $offset-y);
   background-size: (image-width(sprite-path($sprite_retina)) / 2) (image-height(sprite-path($sprite_retina)) / 2);
 }

 @if($dimensions) {
   @include sprite-dimensions($sprite, $name);
 }
}

@mixin icon-retina($name, $offset-x: 0, $offset-y: 0, $dimensions: false) {
 @include sprite-retina($name, $icons, $icons-2x, $offset-x, $offset-y, $dimensions);
}

@mixin arrow-retina($name, $offset-x: 0, $offset-y: 0, $dimensions: false) {
 @include sprite-retina($name, $arrows, $arrows-2x, $offset-x, $offset-y, $dimensions);
}

@mixin easybake-retina($name, $offset-x: 0, $offset-y: 0, $dimensions: false) {
 @include sprite-retina($name, $easybake, $easybake-2x, $offset-x, $offset-y, $dimensions);
}

@mixin hover-img{
  &:before{
    opacity: 0;
  }
  &:after{
    opacity: 1;
  }
}
