@charset "UTF-8";
@font-face {
  font-family: "alevri";
  src: url("../fonts/alevri.woff2?wxuahc") format("woff2"), url("../fonts/alevri.ttf?wxuahc") format("truetype"), url("../fonts/alevri.woff?wxuahc") format("woff"), url("../fonts/alevri.svg?wxuahc#alevri") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.alevri-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "alevri" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.alevri-icon-heart:before {
  content: ""; }

.alevri-icon-envelope:before {
  content: ""; }

.alevri-icon-printer:before {
  content: ""; }

.alevri-icon-user:before {
  content: ""; }

.alevri-icon-location:before {
  content: ""; }

.alevri-icon-magnifier:before {
  content: ""; }

.alevri-icon-cross:before {
  content: ""; }

.alevri-icon-menu:before {
  content: ""; }

#block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-6 {
  position: static; }
  #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-6 a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto; }

#om-leaf-om-u1-1908653427-5 a, #om-leaf-om-u1-1908653427-5 a:hover {
  background-image: url("../images/easybake.svg"); }

.l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content {
  width: 960px;
  left: -490px;
  z-index: 500; }
  .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .om-maximenu-middle-right {
    padding: 0;
    margin-top: 25px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.4); }
    .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .om-maximenu-middle-right:before {
      content: "";
      border-right: 9px solid transparent;
      border-left: 9px solid transparent;
      border-bottom: 9px solid #f5f5f5;
      width: 0;
      height: 0;
      opacity: 0.95;
      position: absolute;
      top: 18px;
      left: 582px; }
  .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu {
    margin: 0;
    padding: 0; }
    .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li {
      display: block;
      float: left;
      margin: 0;
      padding: 0;
      position: relative;
      background: #ffffff; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:after {
        content: "";
        height: 200px;
        position: absolute;
        width: 1px;
        border-right: 1px solid #cccccc;
        bottom: 0;
        right: 0; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li a {
        width: 160px !important;
        background-color: transparent;
        display: block;
        height: 210px;
        text-align: center;
        padding: 150px 25px 25px;
        width: 191px;
        font-size: 16px;
        color: #666666;
        font-weight: 700;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(6):after {
        display: none; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(1) a:before {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/istoria-back.png");
        /*background-image: url('../images/egkatastaseis-back.png');*/
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(1) a:after {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/istoria-back-red.png");
        /*background-image: url('../images/egkatastaseis-back-red.png');*/
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(1) a:hover {
        color: #e21937; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(1) a:hover:before {
          opacity: 0; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(1) a:hover:after {
          opacity: 1; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(2) a:before {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/egkatastaseis-back.png");
        /*background-image: url('../images/sitari-back.png');*/
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(2) a:after {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/egkatastaseis-back-red.png");
        /*background-image: url('../images/sitari-back-red.png');*/
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(2) a:hover {
        color: #e21937; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(2) a:hover:before {
          opacity: 0; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(2) a:hover:after {
          opacity: 1; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(3) a:before {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/sitari-back.png");
        /*background-image: url('../images/ypeythinotita-back.png');*/
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(3) a:after {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/sitari-back-red.png");
        /*background-image: url('../images/ypeythinotita-back-red.png');*/
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(3) a:hover {
        color: #e21937; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(3) a:hover:before {
          opacity: 0; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(3) a:hover:after {
          opacity: 1; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(4) a:before {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/ypeythinotita-back.png");
        /*background-image: url('../images/istoria-back.png');*/
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(4) a:after {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/ypeythinotita-back-red.png");
        /*background-image: url('../images/istoria-back-red.png');*/
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(4) a:hover {
        color: #e21937; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(4) a:hover:before {
          opacity: 0; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(4) a:hover:after {
          opacity: 1; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(5) a:before {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/news-back.png");
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(5) a:after {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/news-back-red.png");
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(5) a:hover {
        color: #e21937; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(5) a:hover:before {
          opacity: 0; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(5) a:hover:after {
          opacity: 1; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(6) a:before {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/desmeusi.png");
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(6) a:after {
        content: "";
        height: 140px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        width: inherit;
        background-image: url("../images/desmeusi-red.png");
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s; }
      .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(6) a:hover {
        color: #e21937; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(6) a:hover:before {
          opacity: 0; }
        .l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .block-menu-id-menu-branding-menu ul.menu li:nth-child(6) a:hover:after {
          opacity: 1; }

#block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 li.leaf {
  display: block;
  float: left;
  margin: 0;
  position: relative;
  background: #ffffff; }

#block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 a, #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 span {
  text-decoration: none;
  color: #e21937;
  font-weight: 700;
  font-size: 18px;
  padding: 0;
  transition-duration: 0.4s; }

#block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7:after {
  content: "";
  width: 0;
  height: 6px;
  border-width: 4px 4px 0;
  border-style: solid;
  border-color: #e21937 transparent;
  display: inline-block;
  margin: 0 0 -4px 3px;
  opacity: 1; }

#block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7:hover:after {
  border-color: #333333 transparent; }

#block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7:hover a, #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7:hover span {
  color: #333333; }

.outer-navigation #block-om-maximenu-om-maximenu-2 ul#om-menu-main-menu li#om-leaf-om-u1-1908653427-2 .om-maximenu-content {
  left: -46px; }

.outer-navigation #block-om-maximenu-om-maximenu-2 ul#om-menu-main-menu li#om-leaf-om-u1-1908653427-2 .om-maximenu-content .om-maximenu-middle-right:before {
  left: 66px; }

#block-menu-menu-mobile-menu > ul.menu > li.last a {
  color: transparent;
  background-image: url("../images/easybake-white.svg");
  background-repeat: no-repeat;
  background-position: 12px;
  background-size: 90px; }

.l-region--navigation #block-menu-menu-mobile-menu ul.menu li [data-toggle="dropdown"] {
  pointer-events: none; }

#block-views-vimeo-slideshow-block .views-slideshow-pager-fields img {
  border: none;
  max-width: 100%;
  max-height: initial; }

.page-node-126 #widget_pager_bottom_vimeo_slideshow-block_1 .views-slideshow-pager-field-item .views-field-field-vimeo-image:before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.page-node-126 #widget_pager_bottom_vimeo_slideshow-block_1 .views-slideshow-pager-field-item {
  float: none;
  margin-bottom: 20px; }

.page-node-126 #widget_pager_bottom_vimeo_slideshow-block_1 {
  display: flex;
  flex-wrap: wrap; }

.facility-section {
  max-width: 970px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.facility {
  max-width: 49%;
  width: 470px;
  margin-bottom: 20px;
  margin-top: 20px; }

@media (max-width: 960px) and (min-width: 0) {
  .facility-section {
    justify-content: center; }
  .facility {
    max-width: 100%;
    width: 470px; }
  .page-node-126 #widget_pager_bottom_vimeo_slideshow-block_1 {
    justify-content: center; }
  #block-views-slideshow-block-1 ul.slides li {
    border-bottom: 5px solid #e21937; }
  #block-views-slideshow-block-1 ul.slides li .views-field-php-1 .slide-image {
    background-size: cover;
    height: 300px;
    background-position: center !important; }
  #block-views-slideshow-block-1 .flexslider .flex-direction-nav a {
    top: 50%;
    transform: translateY(-50%); }
  .flexslider .slides > li:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
    pointer-events: none; }
  #block-views-slideshow-block-1 ul.slides li .banner-text {
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; }
  #block-views-slideshow-block-1 ul.slides li .banner-text .views-field-view-node a:after, #block-views-slideshow-block-1 ul.slides li .banner-text .views-field-field-link a:after,
  #block-views-slideshow-block-1 ul.slides li .banner-text .views-field-view-node a:before, #block-views-slideshow-block-1 ul.slides li .banner-text .views-field-field-link a:before {
    display: none !important; }
  #block-views-slideshow-block-1 ul.slides li .banner-text .views-field-nothing span:after, #block-views-slideshow-block-1 ul.slides li .banner-text .views-field-field-banner-text span:after {
    left: 50%;
    transform: translateX(-50%); }
  #block-views-slideshow-block-1 ul.slides li .banner-text .views-field-nothing {
    color: #fff;
    font-size: 20px; }
  #block-views-slideshow-block-1 ul.slides li .banner-text .views-field-php {
    font-size: 40px;
    color: #fff; }
  #block-views-slideshow-block-1 ul.slides li .banner-text .views-field-field-link a,
  #block-views-slideshow-block-1 ul.slides li .banner-text .views-field-view-node a {
    color: #fff;
    padding: 0; }
  #flexslider-1 ul li img {
    display: none; }
  #block-views-slideshow-block-1 ul.slides li .banner-text {
    border-top: none; }
  .two-col + .two-col {
    margin-top: 32px; }
  .page-node-90 .section1,
  .page-node-90 .section3 {
    background: transparent; } }

@media (max-width: 640px) and (min-width: 0) {
  .page-news .view-latest-articles .views-row {
    display: flex;
    flex-direction: column; }
  .page-news .view-latest-articles .views-row .views-field-body {
    margin-top: 13px; } }

#block-views-slideshow-block-1 .view-slideshow .slides li {
  cursor: pointer; }

.recipe-teaser .views-fieldset .views-field-field-portion {
  margin-right: 10px; }

.view-search-page .views-field-field-difficulty,
.recipe-teaser .views-fieldset .views-field-field-difficulty {
  float: left;
  color: #999999;
  font-size: 14px; }
  .view-search-page .views-field-field-difficulty .field-content,
  .recipe-teaser .views-fieldset .views-field-field-difficulty .field-content {
    display: inline-block; }
    .view-search-page .views-field-field-difficulty .field-content:before,
    .recipe-teaser .views-fieldset .views-field-field-difficulty .field-content:before {
      content: '';
      display: inline-block;
      width: 3px;
      margin-right: 13px;
      height: 7px;
      background: #999999;
      box-shadow: 5px 0px 0 0px #999999, 5px -3px 0 0px #999999, 10px 0px 0 0px #999999, 10px -6px 0 0px #999999; }

.view-search-page .views-field-field-difficulty {
  position: absolute;
  bottom: 3px;
  right: 10px; }

.page-news .view-latest-articles .views-row .views-field-view-node a {
  margin-top: 20px;
  display: inline-block;
  background: #e21937;
  color: #fff;
  padding: 5px 10px 3px; }
  .page-news .view-latest-articles .views-row .views-field-view-node a:hover {
    color: #fff;
    background: #999; }

.view-latest-articles .views-field-title {
  margin-bottom: 20px;
  margin-top: 16px; }

@media (min-width: 0) and (max-width: 640px) {
  .l-branding .l-container .site-logo {
    display: flex;
    width: 170px;
    height: 61px;
    justify-content: center;
    align-items: center; }
  .block-large-banner .bean-image-block h2 {
    margin: 34px 0 9px 0; }
  .page-node-95 #views_slideshow_cycle_main_history_slideshow-block_1_1 {
    padding: 60px 0 40px; }
  .page-node-95 #views_slideshow_cycle_main_history_slideshow-block_1_1 .views-slideshow-cycle-main-frame-row .views-field-field-history-image {
    float: none;
    max-width: 320px;
    margin-right: 0; }
  .page-node-95 #views_slideshow_cycle_main_history_slideshow-block_1_1 .views-slideshow-cycle-main-frame-row .views-field-title {
    float: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 20px; }
    .page-node-95 #views_slideshow_cycle_main_history_slideshow-block_1_1 .views-slideshow-cycle-main-frame-row .views-field-title:after {
      margin: 0 auto;
      right: 0; }
  .page-node-95 #views_slideshow_cycle_main_history_slideshow-block_1_1 .views-slideshow-cycle-main-frame-row .views-field-body {
    width: 100%;
    padding: 0 20px;
    text-align: center; }
  .page-node-95 #views_slideshow_controls_text_history_slideshow-block_1_1 {
    top: 15px;
    right: 50%;
    transform: translateX(50%); }
  .page-node-94 .parallax1,
  .page-node-94 .parallax2,
  .page-node-94 .parallax3,
  .page-node-94 .parallax4 {
    height: auto;
    background-attachment: initial; }
    .page-node-94 .parallax1:before,
    .page-node-94 .parallax2:before,
    .page-node-94 .parallax3:before,
    .page-node-94 .parallax4:before {
      content: '';
      display: block;
      padding-top: 67.1875%; }
  .page-node-94 .enviroment .inner,
  .page-node-94 .society .inner,
  .page-node-94 .people .inner,
  .page-node-94 .product .inner {
    padding: 20px 10%; }
  .page-node-94 .society .two-col {
    padding-top: 35px; }
    .page-node-94 .society .two-col + .two-col {
      margin-top: 0;
      padding-top: 0; }
  #block-views-sol-block-1 {
    margin-bottom: 50px; } }

@media (min-width: 640px) and (max-width: 960px) {
  .page-node-95 #views_slideshow_cycle_main_history_slideshow-block_1_1 .views-slideshow-cycle-main-frame-row .views-field-title {
    margin-top: 20px; } }

#backtotop {
  z-index: 499; }

.outer-navigation #block-om-maximenu-om-maximenu-2 ul#om-menu-main-menu li#om-leaf-om-u1-1908653427-4 .om-maximenu-content .om-maximenu-middle-right:before {
  left: 110px; }

.outer-navigation #block-om-maximenu-om-maximenu-2 ul#om-menu-main-menu li#om-leaf-om-u1-1908653427-1 .om-maximenu-content {
  left: -267px; }

.outer-navigation #block-om-maximenu-om-maximenu-2 ul#om-menu-main-menu li#om-leaf-om-u1-1908653427-1 .om-maximenu-content .om-maximenu-middle-right:before {
  left: 311px; }

.l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content {
  left: -521px; }

.l-header .outer-navigation #block-om-maximenu-om-maximenu-2 #om-leaf-om-u1-1908653427-7 .om-maximenu-content .om-maximenu-middle-right:before {
  left: 630px; }

.page-home-recipes .view-recent-recipes .view-header .view-counter,
.page-user-recipes .view-recent-recipes .view-header .view-counter {
  position: static; }

.page-user-recipes .view-recent-recipes .view-header .view-counter {
  text-align: center; }

#block-views-recent-recipes-block .block__title:after {
  left: 50%;
  transform: translateX(-50%); }

form label.error[generated="true"] {
  color: red;
  font-size: 13px;
  margin-top: 5px; }

.outer-navigation #block-om-maximenu-om-maximenu-2 ul#om-menu-main-menu li#om-leaf-om-u1-1908653427-9 {
  padding-right: 0; }

.page-node-90 .l-main p.under-title,
.page-node-126 .l-main p.under-title,
.page-node-94 .l-main p.under-title,
.page-node-95 .l-main p.under-title {
  text-align: center; }

.page-node-94 .enviroment,
.page-node-94 .society {
  text-align: center; }
  .page-node-94 .enviroment p,
  .page-node-94 .society p {
    margin-top: 35px; }
  .page-node-94 .enviroment .important,
  .page-node-94 .society .important {
    font-size: 22px;
    font-weight: 600; }

.page-node-112 .history,
.page-node-112 .desmeysi {
  text-align: center; }
  .page-node-112 .history p,
  .page-node-112 .desmeysi p {
    margin-top: 35px; }

.page-node-65 .field--name-body {
  text-align: center; }

.webform-client-form-65 .webform-component-markup {
  text-align: center; }

.block-large-banner .bean-image-block h2:after {
  left: 50%;
  transform: translateX(-50%); }

#block-views-sol-block .block__content a {
  display: inline-block;
  padding: 10px 5px; }

.node-type-recipe .recipe-left-column .field--name-field-materials .field-items .recipe-portion input.disabled {
  pointer-events: none; }

.node-type-recipe .recipe-products .product-image li {
  float: none; }

.has-tooltip .tooltip {
  display: none;
  white-space: nowrap;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  padding: 5px 10px 3px;
  margin-bottom: 5px;
  border-radius: 3px;
  color: #fff;
  font-size: 12px; }
  .has-tooltip .tooltip:before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    position: absolute;
    top: 100%;
    left: 0; }
  .has-tooltip .tooltip:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 5px 7px 0;
    border-color: #333 transparent; }

.has-tooltip:hover .tooltip {
  display: block; }

.l-branding .l-container .burger {
  right: auto;
  left: 10px;
  top: 11px; }

.l-branding .l-container .front-link-mobile {
  display: none;
  right: 10px;
  top: 11px; }

.l-branding .l-container .burger.mobile-menu-open,
.l-branding .l-container .burger.mobile-menu-close {
  background: none; }
  .l-branding .l-container .burger.mobile-menu-open:before,
  .l-branding .l-container .burger.mobile-menu-close:before {
    font-family: "alevri";
    font-size: 40px;
    color: #000; }

.l-branding .l-container .burger.mobile-menu-open:before {
  content: ""; }

.l-branding .l-container .burger.mobile-menu-close:before {
  content: "";
  color: #e21937; }

.mobile-search-button, .mobile-user-button {
  font-family: "alevri";
  font-size: 25px;
  height: 41px;
  width: 41px;
  position: absolute;
  top: 11px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .mobile-search-button:before, .mobile-user-button:before {
    font-size: 25px; }

.mobile-user-button {
  right: 5px; }
  .mobile-user-button:before {
    content: ""; }

.mobile-search-button {
  right: 47px; }
  .mobile-search-button:before {
    content: ""; }

@media (min-width: 640px) {
  .l-branding .l-container .site-logo {
    width: 240px; } }

#block-block-8 {
  margin-top: 28px; }

#block-block-8 .block__content:before {
  font-family: "alevri";
  content: "";
  color: #e21937;
  font-size: 17px;
  transform: translateY(1px);
  margin-right: 5px;
  background: none; }

body:not(.logged-in) .mlid-1517 {
  display: none !important; }

body:not(.logged-in) #block-block-8 .block__content a {
  font-weight: normal; }

body:not(.logged-in) #block-block-8 .block__content:before {
  content: "";
  color: #333; }

#block-search-form .container-inline .form-type-textfield input {
  padding: 15px 53px 15px 0;
  color: #333;
  font-size: 16px;
  letter-spacing: normal; }
  #block-search-form .container-inline .form-type-textfield input::placeholder {
    /* Firefox, Chrome, Opera */
    color: #333; }
  #block-search-form .container-inline .form-type-textfield input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #333; }
  #block-search-form .container-inline .form-type-textfield input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #333; }

#block-search-form .container-inline .form-actions {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%); }
  #block-search-form .container-inline .form-actions:before {
    font-family: "alevri";
    content: "";
    font-size: 17px;
    color: #333;
    position: absolute;
    display: block;
    pointer-events: none; }
  #block-search-form .container-inline .form-actions input {
    color: transparent;
    position: static;
    background: none !important; }

@media (min-width: 0) and (max-width: 960px) {
  .page-taxonomy-term .view-taxonomy-term .view-header .view-counter {
    padding-top: 0;
    top: 70px;
    left: 0;
    right: 0; } }

.page-taxonomy-term .view-taxonomy-term .view-content,
body.loaded .view-search-page .view-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.page-search-recipes .outer-view-content .view-header {
  text-align: center; }

#views-exposed-form-search-page-page .views-exposed-form .views-exposed-widget {
  padding-right: 0; }

.field--name-field-hardware {
  margin: 30px 0; }

.view-recent-recipes .recipe-vimeo-inside a:before,
.view-id-taxonomy_term .recipe-vimeo-inside a:before,
.view-related-main-product .recipe-vimeo-inside a:before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.right-fade {
  pointer-events: none; }

.user-profile-form .form-submit {
  width: 226px;
  line-height: 40px;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  background-color: #e21937;
  border: 0;
  color: #ffffff;
  transition-duration: 0.4s;
  width: 260px; }
  .user-profile-form .form-submit:hover {
    background-color: #333; }

.user-profile-form input[type="text"],
.user-profile-form input[type="password"] {
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 0;
  font-size: 16px;
  padding-left: 9px;
  width: 260px;
  padding-top: 9px;
  padding-bottom: 10px; }

.user-profile-form .form-item label {
  padding: 5px 0; }

.user-profile-form .description {
  margin-top: 10px; }

.user-profile-form .form-actions {
  text-align: center;
  margin-bottom: 60px;
  margin-top: 30px; }

.user-profile-form #edit-picture,
.user-profile-form #edit-locale,
.user-profile-form #edit-timezone,
.user-profile-form .password-strength,
.user-profile-form .password-suggestions {
  display: none !important; }

.user-profile-form .password-confirm .error {
  color: #e21937; }

#block-system-user-menu {
  margin-top: 30px;
  margin-left: 10px;
  float: left;
  padding-left: 20px;
  display: none;
  position: static; }
  body.mobile-user-menu-open #block-system-user-menu {
    display: block; }
  @media (min-width: 960px) {
    #block-system-user-menu {
      position: relative;
      display: block; }
      #block-system-user-menu:before {
        font-family: alevri;
        content: "";
        color: #333;
        font-size: 17px;
        cursor: pointer;
        transform: translateY(1px);
        margin-right: 5px;
        background: none;
        position: absolute;
        left: 0;
        top: -3px; } }
  #block-system-user-menu h2 {
    font-size: 16px;
    margin: 0;
    position: relative;
    padding-right: 10px;
    cursor: pointer;
    display: none; }
    @media (min-width: 960px) {
      #block-system-user-menu h2 {
        display: inline-block; } }
    #block-system-user-menu h2:after {
      content: '';
      display: block;
      border-style: solid;
      border-color: #333 transparent;
      border-width: 4px 4px 0;
      position: absolute;
      top: 7px;
      right: 0; }
  #block-system-user-menu ul.menu {
    background: #e21937;
    position: absolute;
    left: 0;
    z-index: 499;
    top: 60px;
    width: 100%;
    padding: 0;
    margin: 0; }
    @media (min-width: 640px) {
      #block-system-user-menu ul.menu {
        top: 73px; } }
    @media (min-width: 960px) {
      #block-system-user-menu ul.menu {
        display: none;
        position: relative;
        background: #fff;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.4);
        list-style: none;
        padding: 20px 25px 18px;
        position: absolute;
        min-width: 240px;
        top: 100%;
        left: 0;
        margin: 10px 0 0; }
        #block-system-user-menu ul.menu:before {
          content: "";
          display: block;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
          border-bottom: 7px solid #ffffff;
          width: 0;
          height: 0;
          position: absolute;
          right: auto;
          top: auto;
          left: 30px;
          bottom: 100%;
          background: transparent; }
        #block-system-user-menu ul.menu:after {
          content: '';
          display: block;
          width: 100%;
          height: 10px;
          position: absolute;
          right: auto;
          top: auto;
          left: 0;
          bottom: 100%;
          background: transparent; } }
    #block-system-user-menu ul.menu li {
      list-style: none;
      width: 100%;
      text-align: left; }
      @media (min-width: 960px) {
        #block-system-user-menu ul.menu li {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #cccccc;
          padding-right: 0;
          font-size: 16px; } }
      #block-system-user-menu ul.menu li a {
        text-decoration: none;
        color: #fff;
        font-weight: 700;
        font-size: 20px;
        border-top: 1px solid #991125;
        display: block;
        padding: 0 20px;
        line-height: 60px; }
        @media (min-width: 960px) {
          #block-system-user-menu ul.menu li a {
            color: #666666;
            font-size: 16px;
            font-weight: 300;
            line-height: 1;
            padding: 0;
            border: none; } }
  @media (min-width: 960px) {
    #block-system-user-menu:hover ul.menu {
      display: inline-block; } }

.dismiss, .dismiss:active, .dismiss:focus, .dismiss:hover {
  background: transparent;
  text-shadow: none;
  box-shadow: none;
  outline: none;
  text-shadow: none; }

.messages.messages--status {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  color: #333;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.4);
  z-index: 499;
  padding-top: 80px;
  border-radius: 3px;
  width: 90%; }
  @media (min-width: 640px) {
    .messages.messages--status {
      max-width: 50%; } }
  @media (min-width: 960px) {
    .messages.messages--status {
      max-width: 25%; } }
  .messages.messages--status:before {
    content: '';
    display: block;
    width: 14px;
    height: 27px;
    border: 2px solid green;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) translateX(-50%);
    position: absolute;
    top: 28px;
    left: 50%; }
  .messages.messages--status:after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    border: 2px solid green;
    position: absolute;
    border-radius: 50%;
    top: 15px;
    left: 50%;
    transform: translateX(-50%); }
  .messages.messages--status .dismiss {
    color: #333; }

.block-block-id-27 {
  float: left;
  width: 290px;
  margin-right: 10px; }
  .block-block-id-27 .title {
    font-family: 'PFFusionSansPro';
    font-weight: 500;
    font-size: 20px;
    color: #333333;
    margin: 0; }
    .block-block-id-27 .title a {
      font-weight: inherit !important; }

.block-block-id-23 {
  margin-bottom: 30px; }

.view-pantry-search {
  padding-bottom: 10px; }

.page-taxonomy-term .view-display-id-page .pager {
  padding-bottom: 35px; }

.view-pantry-search .recipe-teaser .views-fieldset,
.view-taxonomy-term .recipe-teaser .views-fieldset,
.page-search-recipes .recipe-teaser .views-fieldset {
  left: 0;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  width: 100%; }

.view-pantry-search .views-row .views-field-field-portion,
.view-taxonomy-term .views-row .views-field-field-portion,
.page-search-recipes .views-row .views-field-field-portion {
  max-width: 33%; }
  .view-pantry-search .views-row .views-field-field-portion .field-content,
  .view-taxonomy-term .views-row .views-field-field-portion .field-content,
  .page-search-recipes .views-row .views-field-field-portion .field-content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block; }

.page-search-recipes .views-field-title {
  height: 51px;
  overflow: hidden; }

.node-type-article img {
  max-width: 100%;
  height: auto !important; }

#block-bean-mageirepste-me-oti-ehete {
  cursor: pointer; }

.recipe-share .addthis_native_toolbox {
  padding-right: 33px; }

.recipe-share .email-share {
  position: absolute;
  height: 26px;
  width: 26px;
  top: 7px;
  background-color: #848484;
  left: 153px;
  transition: all .2s ease-in-out; }
  .recipe-share .email-share:hover {
    transform: translateY(-4px); }

.published-date-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between; }

.article-share {
  position: relative; }
  .article-share .addthis_native_toolbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-right: 28px;
    height: 31px;
    width: 203px; }
  .article-share .email-share {
    position: absolute;
    height: 26px;
    width: 26px;
    bottom: 5px;
    background-color: #848484;
    right: 1px;
    transition: all .2s ease-in-out; }
    .article-share .email-share:hover {
      transform: translateY(-4px); }

@media print {
  .l-branding .l-container {
    font-family: 'PFDinTextPro';
    border-top: 0 !important; }
    .l-branding .l-container:after {
      display: none; }
    .l-branding .l-container a {
      display: none; }
    .l-branding .l-container .site-logo {
      width: 180px !important;
      height: 60px !important;
      margin-top: 10px;
      padding-top: 0 !important; }
  .page-title {
    border-top: 0 !important; }
    .page-title:after {
      display: none; }
    .page-title .above-title {
      display: none !important;
      margin-top: 0px !important; }
    .page-title h1 {
      font-size: 50px !important; }
    .page-title .site-link {
      display: block !important;
      text-align: center;
      font-weight: 700;
      color: #999999;
      margin: 10px 0; }
  .node--recipe--full .content {
    display: flex;
    flex-flow: wrap; }
    .node--recipe--full .content .recipe-description {
      border: 0 !important;
      margin-bottom: 5px !important;
      width: 100%; }
      .node--recipe--full .content .recipe-description .wrapper {
        width: 502px !important;
        padding: 6px 0 !important; }
      .node--recipe--full .content .recipe-description .field {
        width: 22% !important; }
        .node--recipe--full .content .recipe-description .field:before {
          display: none !important; }
      .node--recipe--full .content .recipe-description .recipe-products {
        padding: 5px 0 !important;
        margin-bottom: 5px !important; }
      .node--recipe--full .content .recipe-description img.printcss {
        width: 45px !important;
        display: inline-block !important;
        margin: 0 33px !important; }
    .node--recipe--full .content .recipe-image {
      padding-bottom: 0px !important;
      width: 100%; }
      .node--recipe--full .content .recipe-image img {
        width: 400px !important; }
    .node--recipe--full .content .recipe-vimeo {
      display: none; }
    .node--recipe--full .content .recipe-products {
      width: 100%;
      border: 0 !important;
      margin-bottom: 8px !important; }
      .node--recipe--full .content .recipe-products .product-image {
        display: block !important; }
    .node--recipe--full .content .recipe-left-column {
      position: relative;
      width: 38%;
      margin-right: 2%;
      float: none; }
      .node--recipe--full .content .recipe-left-column .materials-array .material-item {
        border-bottom: 0 !important; }
      .node--recipe--full .content .recipe-left-column .field--name-field-hardware .field-items .field-item {
        border: 0 !important; }
      .node--recipe--full .content .recipe-left-column .field-label {
        font-size: 28px;
        padding-bottom: 0; }
    .node--recipe--full .content .recipe-right-column {
      position: relative;
      width: 60%;
      float: none; }
      .node--recipe--full .content .recipe-right-column .nutrition-elements,
      .node--recipe--full .content .recipe-right-column .wishilist-login {
        display: none !important; }
      .node--recipe--full .content .recipe-right-column .execution .field-label {
        font-size: 28px;
        padding-bottom: 0; }
  .inline-right {
    display: none !important; }
  .tip {
    display: none; }
  #backtotop {
    display: none; }
  .outer-navigation {
    display: none !important; }
  .front-link-mobile, .burger.mobile-menu-open {
    display: none !important; }
  .l-container {
    max-width: 820px; }
  .l-region--branding,
  .l-copyright {
    display: none !important; }
  .front-link {
    display: none !important; }
  .noprintcss {
    border: 0 !important;
    display: none !important; }
  .recipe-portion {
    display: none; }
  #block-block-2 .copyright {
    border: 0 !important; }
  #block-block-2 .inline-left {
    display: none; }
  #block-block-2 .inline-center {
    position: relative;
    top: 70px;
    height: 50px !important;
    width: 200px !important; }
  #block-block-2 .inline-right {
    display: none; }
  #materials-converter-button {
    display: none; }
  #materials-mail-button {
    display: none; }
  .recipe-share-button {
    display: none !important;
    border: 0 !important; }
  .wishilist-login {
    display: none; }
  footer {
    display: none; }
  .nutrition-elements.printcss {
    display: block;
    font-family: 'PFDinTextPro'; }
    .nutrition-elements.printcss .nutrition-wrapper {
      width: 90px;
      display: inline-block;
      position: relative; }
      .nutrition-elements.printcss .nutrition-wrapper img {
        position: absolute;
        top: 40px;
        left: 10px; }
    .nutrition-elements.printcss .nutrition-wrapper-sugar {
      width: 105px;
      display: inline-block;
      position: relative; }
      .nutrition-elements.printcss .nutrition-wrapper-sugar img {
        position: absolute;
        top: 40px;
        left: 20px; }
    .nutrition-elements.printcss .nutrition-wrapper-salt {
      width: 105px;
      display: inline-block;
      position: relative; }
      .nutrition-elements.printcss .nutrition-wrapper-salt img {
        position: absolute;
        top: 40px;
        left: 20px; }
    .nutrition-elements.printcss h2 {
      text-align: left;
      font-size: 34px;
      font-weight: 300;
      font-family: 'PFFusionSansPro';
      position: relative;
      line-height: 34px;
      position: relative;
      margin: 0;
      letter-spacing: -0.25px; }
    .nutrition-elements.printcss h3 {
      color: #999999;
      font-weight: 300;
      border-bottom: 1px solid #cccccc;
      position: relative;
      margin: 0;
      padding: 4px 0 4px 0;
      font-size: 14px; }
      .nutrition-elements.printcss h3:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -4px;
        height: 1px;
        width: 160px;
        border-bottom: 5px solid #333333; }
      .nutrition-elements.printcss h3 span {
        color: #333333;
        font-weight: 700; }
    .nutrition-elements.printcss .field-collection-container {
      z-index: 1;
      display: inline-block;
      text-align: center;
      position: relative;
      border: 0;
      padding-top: 5px;
      width: 105px; }
      .nutrition-elements.printcss .field-collection-container .field-collection-view-links {
        display: none; }
      .nutrition-elements.printcss .field-collection-container .field-collection-view {
        padding: 0; }
      .nutrition-elements.printcss .field-collection-container .field-label {
        font-family: 'PFDinTextPro';
        color: #333333;
        font-size: 12px;
        font-weight: 700;
        height: 35px;
        vertical-align: middle;
        margin: 0 auto;
        text-align: center;
        display: table-cell;
        width: 123px; }
      .nutrition-elements.printcss .field-collection-container .field--name-field-quantity {
        background-color: transparent;
        color: #fff !important;
        font-size: 14px;
        font-weight: 700;
        height: 72px;
        width: 72px;
        border-radius: 50%;
        display: table;
        margin: 0 auto; }
        .nutrition-elements.printcss .field-collection-container .field--name-field-quantity .field-items {
          display: table-cell;
          vertical-align: middle; }
          .nutrition-elements.printcss .field-collection-container .field--name-field-quantity .field-items .field-item {
            color: white;
            width: 98%;
            margin-left: auto;
            margin-right: auto; }
      .nutrition-elements.printcss .field-collection-container .field--name-field-percentage {
        background-color: transparent;
        color: #999999;
        font-size: 14px;
        font-weight: 700;
        height: 72px;
        width: 72px;
        border-radius: 50%;
        display: table;
        position: relative;
        top: -23px;
        z-index: -1;
        margin: 0 auto; }
        .nutrition-elements.printcss .field-collection-container .field--name-field-percentage .field-items {
          display: table-cell;
          vertical-align: middle; }
          .nutrition-elements.printcss .field-collection-container .field--name-field-percentage .field-items .field-item {
            color: #999999;
            width: 98%;
            margin-left: auto;
            margin-right: auto; }
      .nutrition-elements.printcss .field-collection-container .field--name-field-acids .field-label {
        padding: 0 5px; }
      .nutrition-elements.printcss .field-collection-container .field--name-field-energy {
        padding-righ: 10px; }
      .nutrition-elements.printcss .field-collection-container .field--name-field-acids {
        padding-right: 10px; }
      .nutrition-elements.printcss .field-collection-container .field--name-field-salt {
        padding-left: 10px; }
      .nutrition-elements.printcss .field-collection-container .field--name-field-sugar {
        padding-left: 10px; } }

#block-views-slideshow-block-1 ul.slides li .banner-text.node-Slide .hide-title {
  display: none; }

.l-footer-newsletter {
  background: #e21937;
  color: white;
  padding: 20px 0; }
  @media (min-width: 960px) {
    .l-footer-newsletter {
      padding-bottom: 24px; } }
  .l-footer-newsletter .block-newsletter {
    display: flex;
    flex-flow: wrap;
    position: relative; }
    .l-footer-newsletter .block-newsletter .webform-component--subtitle > p {
      margin: 0; }
      .l-footer-newsletter .block-newsletter .webform-component--subtitle > p > span {
        color: white !important;
        font-family: 'PFDinTextPro' !important; }
    .l-footer-newsletter .block-newsletter #edit-submitted-email {
      width: 100%;
      height: 60px;
      line-height: 60px;
      background: #fff;
      padding: 5px 17px 4px 47px;
      color: black; }
    .l-footer-newsletter .block-newsletter .webform-component--consent .checker {
      float: left;
      margin-right: 10px; }
    .l-footer-newsletter .block-newsletter .webform-component--consent .option {
      font-size: 14px; }
    .l-footer-newsletter .block-newsletter .webform-component--consent .description {
      font-size: 12px; }
      .l-footer-newsletter .block-newsletter .webform-component--consent .description > a {
        color: white;
        text-decoration: underline; }
    .l-footer-newsletter .block-newsletter .webform-submit {
      width: 100%;
      border: 0;
      background: #fff;
      color: #e21937;
      font-weight: 700;
      position: relative;
      display: inline-block;
      line-height: 56px;
      margin: 0;
      padding: 0; }

.page-node-919 .success-icon {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 1px #ccc;
  position: relative;
  margin: 30px 0; }
  .page-node-919 .success-icon:before {
    content: '';
    display: block;
    width: 30px;
    height: 100px;
    border: 2px solid #e21937;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg);
    position: absolute;
    top: -35px;
    left: 58px; }

.page-node-919 .node__content {
  display: flex;
  justify-content: center;
  text-align: center; }

.page-node-919 .return-to-home {
  border-radius: 2px;
  font-size: 16px;
  font-weight: 700;
  background-color: #e21937;
  border: 0;
  color: white;
  padding: 12px 48px; }
