$alevri-icon-font-family: "alevri" !default;
$alevri-icon-font-path: "../fonts" !default;

$alevri-icon-heart: "\e813";
$alevri-icon-envelope: "\e818";
$alevri-icon-printer: "\e81c";
$alevri-icon-user: "\e82a";
$alevri-icon-location: "\e835";
$alevri-icon-magnifier: "\e86f";
$alevri-icon-cross: "\e870";
$alevri-icon-menu: "\e871";
